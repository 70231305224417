<template>
  <div v-html="blogContent" class="blog-page-container"></div>
  <div class="more-blog-pages">
    <h1 class="section-header">{{  $t("BLOG_MORE_HEADER") }}</h1>
    <span class="section-content">{{ $t("BLOG_MORE_CONTENT") }}</span>
  </div>
  <mobile-blog-carousel v-if="global.state.isMobile" :exception="exception" />
  <blog-carousel v-else :exception="exception" style="width: 80%; margin: auto;" />
  <mobile-banner v-if="global.state.isMobile" />
  <homepage-banner v-else />
</template>

<script>
import { ref, onMounted, watch, inject, defineAsyncComponent } from "vue";
import { useRoute } from "vue-router";

import BlogCarousel from "../../../components/web/BlogCarousel";
import MobileBlogCarousel from "@/components/mobile/BlogCarousel";

export default {
  name: "index",
  components: {
    "blog-carousel": BlogCarousel,
    "mobile-blog-carousel": MobileBlogCarousel,
    "homepage-banner": defineAsyncComponent(() => import("@/components/web/HomepageBanner")),
    "mobile-banner": defineAsyncComponent(() => import("@/components/mobile/HomepageBanner"))
  },
  setup() {
    const blogContent = ref(null);
    const exception = ref("");
    const route = useRoute();
    const global = inject("global");

    onMounted(() => {
      blogContent.value = require(`@/assets/blogAssets/blogPages/${route.params.blogId}.html`);
      exception.value = route.params.blogId
    })

    watch(() => route.params.blogId, (value) => {
      if(value) {
        blogContent.value = require(`@/assets/blogAssets/blogPages/${value}.html`);
        exception.value = value
      }
    })

    return {
      blogContent,
      exception,
      global
    }
  }
}
</script>

<style scoped>
  .blog-page-container {
    scroll-snap-align: start;
    position: relative;
    min-height: 100vh;
    padding: 50px 0;
  }
  .blog-page-container:deep(img.blog-banner) {
    border-radius: 20px;
  }
  .blog-page-container:deep(h1),.blog-page-container:deep(h2),.blog-page-container:deep(h3),.blog-page-container:deep(h4),.blog-page-container:deep(p), .blog-page-container:deep(ul),.blog-page-container:deep(ol) {
    padding: 10px 25%;
    color: #2E293D;
    margin-bottom: 0;
  }
  .blog-page-container:deep(p), .blog-page-container:deep(h3), .blog-page-container:deep(li), .blog-page-container:deep(h4),.blog-page-container:deep(h2) {
    text-align: start;
  }
  .blog-page-container:deep(p)  {
    font-size: 18px;
    margin-top: 10px;
  }
  .blog-page-container:deep(h4) {
    font-size: 18px;
    margin-top: 40px;
  }
  .blog-page-container:deep(h2) {
    font-size: 30px;
    margin-top: 80px;
  }
  .blog-page-container:deep(h1) {
    font-size: 50px;
    margin-top: 100px;
  }
  .blog-page-container:deep(.title) {
    margin: 70px 0;
  }
  :deep(.blog-page) {
    margin: 100px 0 10px 0;
    box-sizing: border-box;
  }
  :deep(.tags) {
    display: flex;
    padding: 10px 25%;
    margin-top: 50px;
  }
  :deep(.tag) {
    font-size: 0.8rem;
    border-radius: 10px;
    background-color: #E1E6FD;
    color: #627DFF;
    padding: 5px 15px;
    margin: 10px;
  }
  .more-blog-pages {
    padding: 10px 20%;
    margin-bottom: 30px;
    scroll-snap-align: start;
  }
  @media screen and (max-width: 992px) {
    .blog-page-container {
      padding: 0 0 50px;
    }
    .more-blog-pages {
      padding: 10px 10px;
    }
    .blog-page-container:deep(h1),.blog-page-container:deep(h2),.blog-page-container:deep(h3),.blog-page-container:deep(h4),.blog-page-container:deep(p), .blog-page-container:deep(ul),.blog-page-container:deep(ol) {
      padding: 0 25px;
    }
    .blog-page-container:deep(p) {
      font-size: 13px;
      margin-top: 25px;
    }
    .blog-page-container:deep(h4) {
      font-size: 13px;
      margin-top: 30px;
    }
    .blog-page-container:deep(h2) {
      font-size: 16px;
      margin-top: 35px;
    }
    .blog-page-container:deep(h1) {
      font-size: 24px;
      margin-top: 50px;
    }
    .blog-page-container:deep(li) {
      font-size: 13px
    }
    :deep(.tags) {
      display: flex;
      padding: 10px 10px;
    }
    .blog-page-container:deep(img.blog-banner) {
      width: calc(100% - 50px) !important;
      margin: 25px !important;
    }
    .blog-page-container:deep(h1.title) {
      margin: 0;
    }
  }
</style>