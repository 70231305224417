<template>
  <div class="blog-card-wrapper" @click="openBlog">
    <div class="blog-card" :class="active ? 'blog-card-active' : (past ? 'blog-card-past' : '')">
      <img class="blog-image" :src="require('../../assets/blogAssets/blogImages/' + image)" />
      <h4 class="blog-heading">{{header}}</h4>
      <span class="blog-content">{{content}}</span>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router"
export default {
  name: "MobileBlogCard",
  props: {
    image: {
      type: String
    },
    header: {
      type: String
    },
    content: {
      type: String
    },
    active: {
      type: Boolean,
      default: false
    },
    past: {
      type: Boolean,
      default: false
    },
    blogId: {
      type: String
    }
  },
  setup(props) {
    const router = useRouter()
    const openBlog = () => router.push("/blog/" + props.blogId)
    return {
      openBlog
    }
  }
}
</script>

<style scoped>
.blog-card-wrapper {
  width: 80%;
  margin: 0 3px;
  flex-basis: 80%;
  flex-shrink: 0;
  scroll-snap-align: center;
}
.blog-card {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
  box-sizing: border-box;
  opacity: 0.3;
  transform: scale(0.8);
  transform-origin: 0 20%;
  transition: all 0.35s;
  will-change: transform;
}

.blog-card-active {
  opacity: 1;
  transform-origin: 50% 20%;
  transform: none;
}

.blog-card-past {
  transform-origin: 100% 20%;
}

.blog-image {
  height: 75%;
  width: 100%;
  border-radius: 19px;
  display: block;
}

.blog-card-active .blog-heading, .blog-card-active .blog-content {
  opacity: 1;
}

.blog-heading {
  margin: 40px 0 17px 0;
  font-size: calc(var(--oh) * 2.3);
  line-height: 150%;
  font-weight: 600;
  color: #2E293C;
  opacity: 0;
  transition: all 0.35s;
}

.blog-content {
  text-align: start;
  color: #2E293D;
  font-size: calc(var(--oh) * 1.8);
  line-height: 150%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  visibility: visible;
  opacity: 0;
  transition: all 0.35s;
}
</style>