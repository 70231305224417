<template>
  <div class="blog-container" ref="carouselRef">
    <div class="spacer" />
    <blog-card v-for="(item, index) in blogItems" v-bind:key="index" v-bind="item" :active="index === activeItem" :past="index < activeItem" />
    <div class="spacer" />
  </div>
</template>

<script>
import { ref, onMounted, watch, toRefs } from "vue";

import blogPages from "@/assets/blogAssets/blogPages";
import MobileBlogCard from "@/components/mobile/BlogCard";
export default {
  name: "MobileBlogCarousel",
  components: {
    "blog-card": MobileBlogCard
  },
  props: {
    exception: {
      type: String,
      default: ""
    }
  },
  setup(props) {
    const { exception } = toRefs(props)
    const activeItem = ref(0);
    const carouselRef = ref(null);
    const blogItems = ref(blogPages)
    watch(exception, value => {
      blogItems.value = blogPages.filter(item => value !== item.blogId)
    })
    const scrollListener = () => {
      const scrollLeft = carouselRef.value.scrollLeft
      const containerWidth = carouselRef.value.clientWidth
      activeItem.value = Math.round(scrollLeft /(containerWidth * 0.8 + 2))
    }

    onMounted(() => {
      carouselRef.value.addEventListener("scroll", scrollListener)
    })
    return {
      blogItems,
      activeItem,
      carouselRef
    }
  }
}
</script>

<style scoped>
.blog-container {
  max-width: 100vw;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  display: flex;
  align-items: flex-start;
  margin-bottom: 20vh;
}

.blog-container::-webkit-scrollbar {
  display: none;
}

.spacer {
  width: calc(10% - 3px);
  flex-basis: calc(10% - 3px);
  flex-shrink: 0;
  height: 1px;
}

</style>